<template>
    <div class="">
        <div class="row ps-3 m-0 p-0" v-if="dep == 2 ||dep == 1">
            <div class="col-md-3 row p-0">
                <div class="col-md-4 p-0 pt-2">
                    <p class="fw-bold">Departemen :</p>
                </div>
                <div class="col-md-8 mb-0">
                    <select v-model="departement" class="form-select mb-3" style="width: 100%;" required v-on:change="loadData($event)">
                        <option class="" v-for="option in dept" v-bind:value="option.value" :key="option.value">
                            {{ option.text }}
                        </option>
                    </select>
                </div>
            </div>
            <div class="col">
                <Toolbar
            :click-add="showAdd"
            :click-edit="showEdit"
            :click-refresh="showRefresh"
            :set-filter="loadDataSearch"
            :status-print="false"
            :data   = "recs"
            :fields = "json_fields"
            type    = "xls"
            name    = "Approve.xls"
        />
            </div>
        </div>
        <div class="container-fluid p-0">
            <div id="wrapper">
                <div id="content">
                    <div class="w3-hide-small w3-hide-medium" id="boundary">
                        <table id="header" cellpadding="0" cellspacing="5" border="0" class="table table-hover table-strip">
                            <thead>
                                <tr class="">
                                    <th width="50px">No. </th>
                                    <th v-for="fl in filterByShow" :width="fl.width" :key="fl.name" class="h9 text-center">{{fl.name}}</th>
                                    <th width="50px">Detail</th>
                                </tr>
                            </thead>
                        </table>
                    </div>
                    <div class="scrlt-y" v-bind:style="'height:'+  (screenHeight-215) +'px;'">
                        <table class="table table-hover table-strip table-bordered col-8">
                            <tbody>
                            <tr v-for="(dt, index) in recs" :key="dt.g_name" class="w3-hover-pale-blue w3-small cursor" :class="index == selectedRow ?'w3-pale-blue':''">
                                <td width="50px" class="text-center">
                                    <i :class="index == selectedRow ?'fa fa-caret-right':''"></i>&nbsp;{{dt.idx}}
                                </td>
                                <td class="w3-hide-large" v-on:click="setKlikRow(index, dt)">
                                    <div class="row">
                                        <div class="col-4">Gudang</div>
                                        <div class="col-8">: {{dt.inv_dep}}</div>
                                    </div>
                                    <div class="row">
                                        <div class="col-4">Nama Barang</div>
                                        <span v-html="setStatus(dt.inv_kat)"></span>
                                    </div>
                                    <div class="row">
                                        <div class="col-4">Krat</div>
                                        <div class="col-8">: {{dt.inv_name}}</div>
                                    </div>
                                    <div class="row mb-2">
                                        <div class="col-4">Total</div>
                                        <div class="col-8">: {{dt.total}}</div>
                                    </div>
                                </td>
                                <td v-on:click="setKlikRow(index, dt)" :style="'width:'+findObjFind('width','inv_dep')" class="w3-hide-small w3-hide-medium ">
                                    <span v-html="setDept(dt.inv_dep)"></span>
                                </td>
                                <td v-on:click="setKlikRow(index, dt)" :style="'width:'+findObjFind('width','inv_kat')" class="w3-hide-small w3-hide-medium ">
                                    <span v-html="setStatus(dt.inv_kat)"></span>
                                </td>
                                <td v-on:click="setKlikRow(index, dt)" :style="'width:'+findObjFind('width','inv_name')" class="w3-hide-small w3-hide-medium ">
                                    {{dt.inv_name}}
                                </td>
                                <td v-on:click="setKlikRow(index, dt)" :style="'width:'+findObjFind('width','total')" class="w3-hide-small w3-hide-medium ">
                                    {{dt.total}}
                                    <div v-if="!dt.total">0</div>
                                </td>
                                <td width="50px">
                                    <router-link :to="'/inventaris/' + dt.inv_id" class="btn btn-success btn-sm"><i class="fa fa-eye"></i></router-link>
                                </td>
                            </tr>
                        </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <!-- pagging -->
        <nav class="Page navigation h9 bg-light bg-gradient shadow">
            <div class="d-flex justify-content-center">
                <ul class="pagination text-center">
                    <li class="page-item page-item active"><a :class="pages.halaman == 1 ?'dsb':''" class="page-link" @click="first()" href="#">First</a></li>
                    <li class="page-item page-item active"><a :class="pages.halaman == 1 ?'dsb':''" class="page-link" @click="prev()" href="#">«</a></li>
                    <li class="page-item page-item active"><a class="page-link px-0 px-md-2" href="#">Page {{pages.halaman}} sd {{pages.ttlhalaman}}</a></li>
                    <li class="page-item page-item active"><a :class="pages.halaman == pages.ttlhalaman ?'dsb':''" class="page-link" @click="next()" href="#">»</a></li>
                    <li class="page-item page-item active"><a :class="pages.halaman == pages.ttlhalaman ?'dsb':''" class="page-link" @click="last()" href="#">Last</a></li>
                    <li class="page-item page-item active"><a class="page-link  page-item active" href="#">Rec {{pages.ttlrec}}</a></li>
                    </ul>
            </div>
        </nav>

        <!-- modal add dan edit -->

        <div id="modal-form" class="modal row top-50 start-50 translate-middle ms-md-0 ms-1 modal-md">
            <div class="modal-dialog modal-dialog-scrollable">
                <div class="w3-animate-zoom modal-content">
            <div class="modal-header pb-0">
                <button onclick="document.getElementById('modal-form').style.display='none'"
                class="btn w3-display-topright btn-lg">&times;</button>
                <p class="h9 fw-bold"><i class="mdi mdi-file-document-multiple-outline"></i>&nbsp; Add / Edit data</p>
            </div>
            <div class="modal-body">
                <form class="px-3" id="frmGdg" v-on:submit.prevent="saveData" autocomplete="off">
                    <div class="mb-3 row">
                            <div class="col-12">
                                <label for="inv_name" class="form-label h9 fw-bold">{{findObjFind('name','inv_name')}} :</label>
                                <input ref="inv_name" type="text" class="form-control mb-3" id="inv_name" :placeholder="findObjFind('name','inv_name')" v-model="rec.inv_name">
                            </div>
                            <div class="col-md-9">
                                <label class="form-label h9 fw-bold">{{findObjFind('name','inv_dep')}} :</label>
                                <select v-model="rec.inv_dep" class="form-select mb-3" style="width: 100%;" required >
                                    <option class="" v-for="option in filteredDept" v-bind:value="option.value" :key="option.value">
                                        {{ option.text }}
                                    </option>
                                </select>
                            </div>
                            <div class="col-md-3 mb-3">
                                <label for="inv_kat" class="form-label h9 fw-bold">{{findObjFind('name','inv_kat')}} :</label>
                                <select v-model="rec.inv_kat" id="inv_kat" class="form-select mb-3" style="width: 100%;" required >
                                    <option value="0" class="">Non Stock</option>
                                    <option value="1" class="">Stock</option>
                                </select>
                            </div>
                    </div>
                </form>
            </div>
            <div class="modal-footer"  style="margin-top: 15px;">
                <h6 class="w3-right">
                <button form="frmGdg" class="btn btn-success rounded" type="submit"><i class="fa fa-save"></i> Save</button>
                <button type="button" class="btn btn-danger ms-2 rounded" onclick="document.getElementById('modal-form').style.display='none'"><i class="fa fa-close"></i> Close</button>
                </h6>
            </div>
            </div>
        </div>
        </div>
    </div>
</template>
<script>
/* eslint-disable */
import Config from '@/conf/Config.js';
import axios from "axios";
import { Crypton } from 'laravel-crypton';
import "vue-search-select/dist/VueSearchSelect.css";
import Toolbar from "@/components/Toolbar.vue";
import { ModelListSelect } from "vue-search-select";

import "datatables.net-bs5/js/dataTables.bootstrap5.min.js";
import "datatables.net-bs5/css/dataTables.bootstrap5.min.css";
import "datatables.net-buttons-bs5/js/buttons.bootstrap5"
import "datatables.net-buttons-bs5/css/buttons.bootstrap5.min.css"
import "datatables.net-buttons/js/buttons.html5.js";
import $ from "jquery";

export default {
    name : 'Inventaris',
    components: {
        Toolbar
    },
    data (){
        return{
            rec: {},
            screenHeight : 0,
            selectedRow : -1,
            dep: localStorage.dept,
            departement : this.finalDept(),
            dept: [
                { text: 'IT', value: '1' },
                { text: 'Kendaraan', value: '3' },
                { text: 'Gudang', value: '4' },
                { text: 'Semua', value: '9' },
            ],
            pages : {halaman : 1, batas : 0, ttlhalaman : 0, ttlrec:0, limit:Config.perpage, search:'',dept : this.finalDept(),},
            filters : {
            	operator : 'AND',
                fields :[
                        {fieldFind : '', key : 'inv_dep', type: 'text', name : 'Departemen', filter : true, width : '200px', grid : true},
                        {fieldFind : '', key : 'inv_kat', type: 'text', name : 'Kategori Item', filter : true, width : '100px', grid : true},
                        {fieldFind : '', key : 'inv_name', type: 'text', name : 'Nama Item', filter : true, width : '500px', grid : true},
                        {fieldFind : '', key : 'total', type: 'text', name : 'Total', filter : true, width : '50px', grid : true},
                ],
                find : [],

            },
            recs : [],
             /**Untuk transfer excel*/
             json_fields : {
                'SO' : 'ta_so',
                'NAMA CUSTOMER' : 'ta_IdCust',
                'TANGGAL PENGAJUAN' : 'ta_date',
                'OUT STANDING SO' : 'ta_outstandingSO',
                'NOMINAL' : 'ta_amt',
                'KREDIT LIMIT' : 'ta_creditLimit',
                'TEMPO' : 'ta_top',
                'PEMBUAT SO' : 'changed_by',
                'STATUS' : 'ta_approved',
                'ALASAN PENGAJUAN' : 'ta_reason'
            },
        }

    },
    methods : {
        findObjFind(key, val){
            return this.findObj(this.filters.fields,'key',val)[key];
        },
        findField(val){
            return this.findObj(this.fieldRules, 'field',val);
        },
        findObj(obj, key, value) {
            let ketemu = 0;
            for (var pkey in obj) {
                if (obj[pkey][key] == value) {
                    break;
                } else {
                    ketemu++;
                }
            }
            if(obj[ketemu]!=undefined){
                return obj[ketemu];
            }else
                return null;
        },
        first(){
            let self = this;
            self.pages.halaman = 1;
            self.loadData();
        },
        prev(){
            let self = this;
            self.pages.halaman = self.pages.halaman - 1;
            self.loadData();
        },
        next(){
            let self = this;
            self.pages.halaman = self.pages.halaman + 1;
            self.loadData();
        },
        last(){
            let self = this;
            self.pages.halaman = self.pages.ttlhalaman;
            self.loadData();
        },
        setKlikRow(index, dt){
            let self = this;
            self.selectedRow = index;
            self.rec = Object.assign({}, dt);
        },
        loadData(){
            this.screenHeight = window.innerHeight;
            let self = this;
            self.selectedRow = -1;
            self.dept;
            self.rec = {};
            let params = {
                pfunction:'show',
                limit: self.pages.limit,
                page: self.pages.halaman,
                search: self.pages.search,
                dept : self.departement
            }
            axios.post("invent/ApiInv.php",params).then(function (response) {
                console.clear();
                let idx = (self.pages.limit*self.pages.halaman)-self.pages.limit+1;
                self.pages.ttlrec = parseFloat(response.data.ttlrec);
                self.pages.ttlhalaman = Math.ceil(parseFloat(response.data.ttlrec)/self.pages.limit);
                self.pages.dept = self.pages.dept;
                response.data.rec.map(function(val){
                    val['idx'] = idx;
                    idx++;
                });
                self.recs = response.data.rec;
            });
        },
        showAdd() {
            let self = this;
            self.stsAdd = true;

            self.rec = {
                inv_name : '',
                inv_dep: '',
                inv_kat : '',
            };
            document.getElementById("modal-form").style.display = "block";

        },
        showEdit(){
            let self = this;
            if (self.selectedRow < 0) {
                this.$toast.error('pilih Item.!');
                return false;
            }else{
                self.stsAdd = false;
                document.getElementById('modal-form').style.display='block';

            }
        },
        async saveData(event) {
                try {
                    let self = this;

                    if (self.stsAdd == true) {
                        self.rec.pfunction = "add";
                    } else {
                        self.rec.pfunction = "edit";
                    }

                    const response = await axios.post("invent/ApiInv.php", self.rec);

                    if (response.data.success) {
                        this.$toast.success(response.data.msg);
                        document.getElementById('modal-form').style.display = 'none';
                        await this.loadData();
                    } else {
                        this.$toast.error(response.data.msg);
                    }
                } catch (error) {
                    console.error("An error occurred while saving data:", error);
                }

                event.preventDefault();
                return false;
            },
        setStatus(dt) {
            if (dt === "1") {
                return "<div class='btn btn-success btn-sm' >Stock</span></div>";
            } else {
                return "<div class='btn btn-danger btn-sm'>Non Stock</span><div>";
            }
        },
        setDept(dt) {
            if (dt === "1") {
                return "<div class='btn btn-light btn-sm' >IT</span></div>";
            } else if ( dt === "2") {
                return "<div class='btn btn-light btn-sm'>Purchasing</span><div>";
            } else if ( dt === "3") {
                return "<div class='btn btn-light btn-sm'>Kendaraan</span><div>";
            } else if ( dt === "4") {
                return "<div class='btn btn-light btn-sm'>Gudang</span><div>";
            } else {
                return "<div class='btn btn-light btn-sm'>Kantor</span><div>";
            }
        },
        showRefresh(){
            this.pages.search = "";
            this.loadData();
        },
        loadDataSearch(filter){
            this.pages.search = filter;
            this.loadData();
        },
        finalDept() {
        const localStorageDept = parseInt(localStorage.dept);
        return localStorageDept === 2 ? '9' : localStorageDept;
        },
    },
    watch: {
        // Watch for changes in localStorage.dept and update departement accordingly
        'localStorage.dept': 'finalDept',
    },
    filters: {
        toRp(value) {
            return `${Intl.NumberFormat("id-ID",{ style: 'currency', currency: 'IDR' }).format(value)}`;
        },
        pNumber(num) {
            var x = num.replace(/\D/g, '').match(/(\d{0,4})(\d{0,4})(\d{0,4})/);
            return !x[2] ? x[1] : '(' + x[1] + ')-' + x[2] + (x[3] ? '' + x[3] : '');
        }
    },
    watch: {
        // 'moveCenter': function(){
        //     console.log("OK");
        // }
    },
    computed :{
        filterByShow(){
            return  this.filters.fields.filter(event => {
                return event.grid == true;
            });
        },
        filteredDept() {
        // Gunakan metode filter untuk menyaring departemen yang memiliki nilai value bukan 9
        return this.dept.filter((option) => option.value !== '9');
        },
    },
    mounted(){
        this.screenHeight = window.innerHeight;
        // this.setInit();
        this.loadData();

    }
};
</script>